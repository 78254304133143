import {
  Chart,
  ChartAxis,
  ChartAxisProps,
  ChartBar,
  ChartBarProps,
  ChartLegend,
  ChartLegendProps,
  ChartPie,
  ChartPieProps,
  ChartProps,
  ChartVoronoiContainer,
} from '@patternfly/react-charts'
import { Alert, Card, CardBody, CardTitle, DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Gallery, Title } from '@patternfly/react-core'
import { Table, TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import React from 'react'

interface Props {
  chartData: Record<string, unknown>[]
}

const component: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <Gallery hasGutter minWidths={{ xl: '420px' }}>
        {props.chartData.map(
          (chart, index) =>
            chart['visible'] === true && (
              <Card isCompact isFullHeight key={index} style={{ minHeight: '340px', borderRadius: '12px' }}>
                <CardTitle>
                  <Title headingLevel="h4" size="md">
                    {chart['name'] as string}
                  </Title>
                </CardTitle>
                <CardBody isFilled>
                  {(chart['type'] as string) === 'pie' && (
                    <ChartPie
                      labels={({ datum }) => `${datum.x}: ${datum.y}`}
                      {...(chart.chartPieProps as ChartPieProps)}
                      legendComponent={
                        <ChartLegend x={0} y={250} symbolSpacer={5} gutter={10} rowGutter={0} padding={0} {...(chart.chartLegendProps as ChartLegendProps)}></ChartLegend>
                      }
                    />
                  )}

                  {(chart['type'] as string) === 'bar' && (
                    <Chart
                      domainPadding={{ x: [5, 5] }}
                      containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y}`} constrainToVisibleArea voronoiDimension="x" responsive mouseFollowTooltips />}
                      {...(chart.chartProps as ChartProps)}
                    >
                      <ChartAxis {...(chart.chartAxisYProps as ChartAxisProps)} />
                      <ChartAxis {...(chart.chartAxisXProps as ChartAxisProps)} />
                      <ChartBar {...(chart.chartBarProps as ChartBarProps)} />
                    </Chart>
                  )}

                  {(chart['type'] as string) === 'stats' && (
                    <React.Fragment>
                      <DescriptionList isHorizontal isCompact termWidth="calc(100% - 50px)" className="pf-u-mt-lg">
                        {(chart['data'] as Array<{ name: string; value: string }>).map((row, index) => (
                          <DescriptionListGroup key={index}>
                            <DescriptionListTerm>{row['name']}</DescriptionListTerm>
                            <DescriptionListDescription>{row['value']}</DescriptionListDescription>
                          </DescriptionListGroup>
                        ))}
                      </DescriptionList>
                    </React.Fragment>
                  )}

                  {(chart['type'] as string) === 'tabular' && (
                    <React.Fragment>
                      {chart['data']?.['alert'] === true && <Alert variant={chart['data']?.['type']} isInline title={chart['data']?.['message']} />}

                      {chart['data']?.['alert'] !== true && (
                        <TableComposable variant="compact">
                          <Thead>
                            <Tr>
                              {(chart['data'] as Array<Array<{ name: string }>>)?.[0]?.map((col, index) => (
                                <Th key={index}>{col['name']}</Th>
                              ))}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {(chart['data'] as Array<Array<{ name: string; key: string; value: string }>>)?.map((row, index) => (
                              <Tr key={index}>
                                {row.map((col, index) => (
                                  <Td key={index}>{col.value.toString()}</Td>
                                ))}
                              </Tr>
                            ))}
                          </Tbody>
                        </TableComposable>
                      )}
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            )
        )}
      </Gallery>
    </React.Fragment>
  )
}

const AnalysisCharts = component

export { AnalysisCharts }
