import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  PageSection,
  Title,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Bullseye,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
  Spinner,
  Alert,
  Modal,
  ToolbarGroup,
  ToolbarFilter,
  InputGroup,
  TextInput,
  ButtonVariant,
  Dropdown,
  DropdownItem,
  KebabToggle,
  TextContent,
  Text,
} from '@patternfly/react-core'
import { Table, Thead, Tr, Th, Tbody, Td, TableVariant } from '@patternfly/react-table'
import { SearchIcon, CubesIcon } from '@patternfly/react-icons'
import { ITenant } from '@lib/Tenant/ITenant'
import Tenant from '@lib/Tenant/Tenant'
import { useAxios } from '@app/utils/useAxios'

export const TenantManagementHome: React.FC = () => {
  const [tenants, setTenants] = useState<ITenant[]>([])
  const [filteredTenants, setFilteredTenants] = useState<ITenant[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState<ITenant | null>(null)
  const [isKebabOpen, setIsKebabOpen] = useState<{ [key: number]: boolean }>({})
  const history = useHistory()
  const axiosInstance = useAxios()
  const api = axiosInstance.current

  const fetchTenants = async () => {
    try {
      setError(null)
      setIsLoading(true)
      if (!api) {
        setTenants([])
        setFilteredTenants([])
        return
      }
      const fetchedTenants = await Tenant.listTenants(api)
      setTenants(Array.isArray(fetchedTenants) ? fetchedTenants : [])
      setFilteredTenants(Array.isArray(fetchedTenants) ? fetchedTenants : [])
    } catch (error) {
      setError('Error fetching tenants. Please try again later.')
      console.error('Error fetching tenants:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (api) {
      fetchTenants()
    }
  }, [api])

  useEffect(() => {
    const filtered = tenants.filter((tenant) => Object.values(tenant).some((value) => value?.toString().toLowerCase().includes(searchValue.toLowerCase())))
    setFilteredTenants(filtered)
  }, [searchValue, tenants])

  const handleCreateNew = () => {
    history.push('/system_admin/tenants/create')
  }

  const handleEdit = (tenantId: number) => {
    history.push(`/system_admin/tenants/edit/${tenantId}`)
  }

  const handleDeleteClick = (tenant: ITenant) => {
    setSelectedTenant(tenant)
    setIsDeleteModalOpen(true)
  }

  const handleDelete = async () => {
    if (!selectedTenant || !api) return

    try {
      setError(null)
      const response = await Tenant.deleteTenant(api, selectedTenant.tenantId)
      if (response.success) {
        fetchTenants()
        setIsDeleteModalOpen(false)
        setSelectedTenant(null)
      }
    } catch (error) {
      setError('Error deleting tenant. Please try again later.')
      console.error('Error deleting tenant:', error)
    }
  }

  const onKebabToggle = (tenantId: number) => {
    setIsKebabOpen((prev) => ({
      ...prev,
      [tenantId]: !prev[tenantId],
    }))
  }

  const kebabItems = (tenant: ITenant) => [
    <DropdownItem key="edit" onClick={() => handleEdit(tenant.tenantId)}>
      Edit
    </DropdownItem>,
    <DropdownItem key="delete" onClick={() => handleDeleteClick(tenant)}>
      Delete
    </DropdownItem>,
  ]

  const renderTable = () => {
    if (isLoading) {
      return (
        <Bullseye>
          <Spinner size="xl" />
        </Bullseye>
      )
    }

    if (filteredTenants.length === 0) {
      return (
        <EmptyState>
          <EmptyStateIcon icon={CubesIcon} />
          <Title headingLevel="h2" size="lg">
            No tenants found
          </Title>
          <EmptyStateBody>
            {searchValue ? 'No tenants match the current filter criteria. Clear all filters and try again.' : 'No tenants are available. Create a new tenant to get started.'}
          </EmptyStateBody>
          <Button variant="primary" onClick={handleCreateNew}>
            Create new tenant
          </Button>
        </EmptyState>
      )
    }

    return (
      <Table aria-label="Tenants table" variant={TableVariant.compact}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Environment</Th>
            <Th>User group</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredTenants.map((tenant) => (
            <Tr key={tenant.tenantId}>
              <Td>{tenant.handle}</Td>
              <Td>{tenant.metadata?.description || '-'}</Td>
              <Td>{tenant.metadata?.environment || '-'}</Td>
              <Td>{tenant.backofficeRealm}</Td>
              <Td>
                <Dropdown
                  isOpen={isKebabOpen[tenant.tenantId]}
                  toggle={<KebabToggle onToggle={() => onKebabToggle(tenant.tenantId)} />}
                  isPlain
                  position="right"
                  dropdownItems={kebabItems(tenant)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    )
  }

  return (
    <>
      <PageSection variant="light">
        <TextContent>
          <Text component="h1">Tenant management</Text>
          <Text component="p">Edit existing and create new tenants</Text>
        </TextContent>
      </PageSection>
      <PageSection>
        {error && (
          <Alert variant="danger" title="Error" isInline>
            {error}
          </Alert>
        )}
        <Toolbar>
          <ToolbarContent>
            <ToolbarGroup variant="filter-group">
              <ToolbarItem>
                <InputGroup>
                  <TextInput name="search" id="search" type="search" aria-label="search tenants" placeholder="Search tenants" value={searchValue} onChange={setSearchValue} />
                  <Button variant={ButtonVariant.control} aria-label="search button">
                    <SearchIcon />
                  </Button>
                </InputGroup>
              </ToolbarItem>
            </ToolbarGroup>
            <ToolbarItem alignment={{ default: 'alignRight' }}>
              <Button variant="primary" onClick={handleCreateNew}>
                Create new tenant
              </Button>
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
        {renderTable()}
      </PageSection>

      <Modal
        variant="small"
        title="Delete tenant"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actions={[
          <Button key="confirm" variant="danger" onClick={handleDelete}>
            Delete
          </Button>,
          <Button key="cancel" variant="link" onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>,
        ]}
      >
        Are you sure you want to delete tenant "{selectedTenant?.handle}"? This action cannot be undone.
      </Modal>
    </>
  )
}

export default TenantManagementHome
