import React from 'react'
import { PageSection, Gallery, GalleryItem, Card, CardTitle, CardBody, CardProps, Title, CardHeaderMain, CardHeader, Icon } from '@patternfly/react-core'
import { useHistory } from 'react-router-dom'
import {
  BsFileEarmarkSpreadsheet,
  RiGeminiLine,
  LuUserCog,
  PiBookOpenTextLight,
  PiPaletteLight,
  PiUsersThreeLight,
  PiWebhooksLogoLight,
  HiOutlineDocumentCheck,
  HiOutlineClipboardDocumentCheck,
  DataSourceIcon,
  AiOutlineWechatWork,
  PiToolboxLight,
  PiPackageThin,
  VscServerProcess,
  PiFolderUserLight,
} from '@lib/icons'

const DashboardHome: React.FunctionComponent = () => {
  const history = useHistory()
  const cardProps: CardProps = {
    isFullHeight: true,
    hasSelectableInput: true,
    isSelectableRaised: true,
  }

  return (
    <React.Fragment>
      <PageSection type="default" variant="default" className="c-background-light-gradient">
        <Title className="pf-u-mb-md" headingLevel="h2">
          Data collection
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/main')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiFolderUserLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Customer data sets</CardTitle>
              </CardHeader>
              <CardBody>Track and access incoming customer data.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/evaluations')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <HiOutlineClipboardDocumentCheck />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Evaluations</CardTitle>
              </CardHeader>
              <CardBody>Review collected product evaluations.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/channels')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <DataSourceIcon />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Channels</CardTitle>
              </CardHeader>
              <CardBody>Identify sources of data sets and evaluations.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/data_definitions')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <BsFileEarmarkSpreadsheet />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Data definitions</CardTitle>
              </CardHeader>
              <CardBody>Define and control collected data types.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Data refinement
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data_refinement/main')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <VscServerProcess />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Refinement models</CardTitle>
              </CardHeader>
              <CardBody>Monitor refinement model workflows.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data_refinement/success_indicators')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <HiOutlineDocumentCheck />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Success indicators</CardTitle>
              </CardHeader>
              <CardBody>Review performance metrics and success indicators.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Intelligence
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/model/bayesian')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <RiGeminiLine />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Intelligence</CardTitle>
              </CardHeader>
              <CardBody>Customise and manage intelligent recommendation models.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Insights
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/chat-assistant')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <AiOutlineWechatWork />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">AI chat</CardTitle>
              </CardHeader>
              <CardBody>Interact and uncover insights through conversational prompts.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/profile-analysis')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiUsersThreeLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Users</CardTitle>
              </CardHeader>
              <CardBody>Filter and analyse user-specific results.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/formulation-analysis')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiBookOpenTextLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Formulations</CardTitle>
              </CardHeader>
              <CardBody>Analyse and explore results based on product formulations.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/ingredient-analysis')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiPaletteLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Ingredients</CardTitle>
              </CardHeader>
              <CardBody>Discover results tailored by ingredient filters.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Products
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/product/main')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiPackageThin />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Products</CardTitle>
              </CardHeader>
              <CardBody>Organise and manage product SKUs.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/product/formulations')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiBookOpenTextLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Formulations</CardTitle>
              </CardHeader>
              <CardBody>Create and modify formulations.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/product/ingredients')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiPaletteLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Ingredients</CardTitle>
              </CardHeader>
              <CardBody>Administer and track ingredients in formulations.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          API keys
        </Title>

        <Gallery hasGutter>
          {/*<GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/customer')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <LuUserCog />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">User management</CardTitle>
              </CardHeader>
              <CardBody>Access user management.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/customer')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiToolboxLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Tools</CardTitle>
              </CardHeader>
              <CardBody>Various operation tools.</CardBody>
            </Card>
          </GalleryItem>*/}
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/api_keys')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiWebhooksLogoLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">API keys</CardTitle>
              </CardHeader>
              <CardBody>Control and maintain API access keys.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>
      </PageSection>
    </React.Fragment>
  )
}

export { DashboardHome }
