import { NavLink, useLocation, useHistory } from 'react-router-dom'
import {
  Nav,
  NavList,
  NavItem,
  NavExpandable,
  Page,
  PageHeader,
  PageSidebar,
  SkipToContent,
  PageHeaderTools,
  Tooltip,
  ApplicationLauncher,
  ApplicationLauncherItem,
  Masthead,
  MastheadToggle,
  PageToggleButton,
  MastheadMain,
  MastheadBrand,
  MastheadContent,
  Icon,
  ApplicationLauncherSeparator,
} from '@patternfly/react-core'
import { routes, IAppRoute, IAppRouteGroup, AppRouteConfig } from '@app/routes/routes'
import logoWhite from '@app/assets/bgimages/NOIE_LOGO_WHITE.png'
import logoWhiteSkinNature from '@app/assets/bgimages/skin_nature_logo_transparent.png'
import logoNeutrogena from '@app/assets/bgimages/neutrogena_logo.png'
import * as React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { BarsIcon, ShareSquareIcon } from '@patternfly/react-icons'
import { useCookies } from 'react-cookie'
import Tenant from '@lib/Tenant/Tenant'
import { useConfig } from '@app/utils/ConfigContext'
import { ITenant } from '@lib/Tenant/ITenant'

import Noie_logo from '@app/assets/bgimages/NOIE_LOGO_BLACK.png'
import Aveeno_logo from '@app/assets/bgimages/Aveeno_logo.png'
import Clean_Clear_Logo from '@app/assets/bgimages/Clean_Clear_Logo.png'
import lubriderm_logo from '@app/assets/bgimages/lubriderm_logo.png'
import Neotrata_logo from '@app/assets/bgimages/Neotrata_logo.png'
import Neutrogena_logo from '@app/assets/bgimages/Neutrogena_logo_t.png'
import DrCiLabo_logo from '@app/assets/bgimages/Dr.Ci.Labo.png'

interface IAppLayout {
  children: React.ReactNode
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(false)
  const [isMobileView, setIsMobileView] = React.useState(true)
  const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false)
  const [cookies, setCookie, removeCookie] = useCookies()
  const { currentTenantId, setCurrentTenantId } = useConfig()

  const identity = useKeycloak()

  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile)
  }
  const onNavToggle = () => {
    setIsNavOpen(!isNavOpen)
  }
  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView)
  }

  const handleLogoutClick = (e: MouseEvent | React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<Element>) => {
    e.preventDefault()
    const logoutUrl = identity.keycloak.createLogoutUrl({ redirectUri: document.URL })
    removeCookie(Tenant.CookieName)
    document.location = logoutUrl
  }

  function LogoImg() {
    const history = useHistory()
    const [isOpen, setIsOpen] = React.useState(false)
    const onToggle = (isOpen: boolean) => setIsOpen(isOpen)
    const onSelect = (_event: any) => setIsOpen((prevIsOpen) => !prevIsOpen)
    const [tenants, setTenants] = React.useState([] as ITenant[])

    React.useEffect(() => {
      const abortController = new AbortController()
      let isMounted = true

      if (!Tenant.getTenants().length) {
        Tenant.fetchTenantsPub().then((tenants) => {
          if (isMounted) {
            setTenants(tenants ? tenants : [])
          }
        })
      } else {
        setTenants(Tenant.getTenants())
      }

      return () => {
        isMounted = false
        abortController.abort()
      }
    }, [])

    const hardcodedALI: React.ReactElement[] = [
      <ApplicationLauncherItem key="key_01" component="button" icon={<img src={Aveeno_logo}></img>} onClick={() => {}} style={{ minWidth: '240px' }}>
        Aveeno
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_02" component="button" icon={<img src={DrCiLabo_logo}></img>} onClick={() => {}} style={{ minWidth: '240px' }}>
        Dr.Ci:Labo
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_03" component="button" icon={<img src={Neotrata_logo}></img>} onClick={() => {}} style={{ minWidth: '240px' }}>
        Neostrata
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_04" component="button" icon={<img src={lubriderm_logo}></img>} onClick={() => {}} style={{ minWidth: '240px' }}>
        Lubriderm
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_05" component="button" icon={<img src={Clean_Clear_Logo}></img>} onClick={() => {}} style={{ minWidth: '240px' }}>
        Clean & Clear
      </ApplicationLauncherItem>,
    ]

    const appLaiuncherBottomNav = [
      <ApplicationLauncherSeparator key="separator" />,
      <ApplicationLauncherItem key="key_100" component="button" onClick={() => history.push('/system_admin/tenants')}>
        Tenant manager
      </ApplicationLauncherItem>,
    ]

    const appLauncherItems: React.ReactElement[] = [
      ...tenants.map((tenant, i) => {
        return (
          <ApplicationLauncherItem
            key={'key_' + i}
            component="button"
            icon={<img src={tenant.tenantId == 3 ? Neutrogena_logo : Noie_logo}></img>}
            onClick={(e) => handleLogoutClick(e)}
            style={{ minWidth: '240px' }}
          >
            {tenant.handle}
          </ApplicationLauncherItem>
        )
      }),
      ...hardcodedALI,
      ...appLaiuncherBottomNav,
    ]

    const tenant = Tenant.getCurrentTenant()
    let logo = Noie_logo

    if (tenant?.tenantId === 3) logo = Neutrogena_logo

    return (
      <React.Fragment>
        <MastheadBrand onClick={() => history.push('/')} target="_blank">
          <img src={logo} width={100} alt="NØIE" />
        </MastheadBrand>
        <ApplicationLauncher onSelect={onSelect} onToggle={onToggle} isOpen={isOpen} items={appLauncherItems} removeFindDomNode={true} />
      </React.Fragment>
    )
  }

  const HeaderTools = () => {
    const name = identity.keycloak.tokenParsed ? identity.keycloak.tokenParsed['given_name'] : ''
    const email = identity.keycloak.tokenParsed ? identity.keycloak.tokenParsed['email'] : ''

    return (
      <PageHeaderTools>
        <span className="pf-u-display-inline-block pf-u-pr-md">
          {name} ({email})
        </span>
        <Tooltip content={'Logout'}>
          <a href="#" onClick={(e) => handleLogoutClick(e)}>
            <ShareSquareIcon color="white"></ShareSquareIcon>
          </a>
        </Tooltip>
      </PageHeaderTools>
    )
  }

  const Header = (
    <Masthead backgroundColor="light">
      <MastheadToggle>
        <PageToggleButton
          variant="plain"
          className="pf-c-button-icon-correction"
          aria-label="Global navigation"
          isNavOpen={isNavOpen}
          onNavToggle={isMobileView ? onNavToggleMobile : onNavToggle}
          id="vertical-nav-toggle"
        >
          <BarsIcon />
        </PageToggleButton>
      </MastheadToggle>
      <MastheadMain>
        <LogoImg />
      </MastheadMain>
      <MastheadContent>
        <HeaderTools />
      </MastheadContent>
    </Masthead>
  )

  const location = useLocation()

  const renderNavItem = (route: IAppRoute, index: number) => {
    const userRoles = identity.keycloak.tokenParsed?.realm_access?.roles ?? []
    const role = (route as IAppRoute).role ?? undefined

    if (role && userRoles.includes(role)) {
      return (
        <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`} isActive={route.path === location.pathname}>
          <NavLink exact={route.exact} to={route.path}>
            {route.icon ? (
              <Icon isInline size="lg" className="pf-u-mr-sm">
                {route.icon}
              </Icon>
            ) : (
              ''
            )}
            {route.label}
          </NavLink>
        </NavItem>
      )
    } else {
      return <React.Fragment key={`${route.label}-${index}`}></React.Fragment>
    }
  }

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => {
    const userRoles = identity.keycloak.tokenParsed?.realm_access?.roles ?? []
    const groupRoles = group.routes.filter((p) => typeof p.role === 'string').map((p) => p.role)
    let shouldRenderGroup = false

    for (const userRole of userRoles) {
      if (groupRoles.includes(userRole)) {
        shouldRenderGroup = true
      }
    }

    if (shouldRenderGroup) {
      return (
        <NavExpandable
          key={`${group.label}-${groupIndex}`}
          id={`${group.label}-${groupIndex}`}
          title={group.label}
          isActive={group.routes.some((route) => route.path === location.pathname)}
          isExpanded={group.routes.some((route) => route.path === location.pathname)}
        >
          {group.routes.map((route, idx) => route.label && renderNavItem(route, idx))}
        </NavExpandable>
      )
    } else {
      return <React.Fragment key={`${group.label}-${groupIndex}`}></React.Fragment>
    }
  }

  const shouldRenderNavItem = (route: AppRouteConfig): boolean => {
    if (typeof route.show !== 'undefined' && route.show === false) return false

    return true
  }

  const Navigation = (
    <Nav id="nav-primary" theme="light">
      <NavList id="nav-list">{routes.map((route, idx) => shouldRenderNavItem(route) && (!route.routes ? renderNavItem(route, idx) : renderNavGroup(route, idx)))}</NavList>
    </Nav>
  )

  const Sidebar = <PageSidebar className="c-sidebar" theme="light" nav={Navigation} isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen} />

  const pageId = 'primary-app-container'

  const PageSkipToContent = (
    <SkipToContent
      onClick={(event) => {
        event.preventDefault()
        const primaryContentContainer = document.getElementById(pageId)
        primaryContentContainer && primaryContentContainer.focus()
      }}
      href={`#${pageId}`}
    >
      Skip to Content
    </SkipToContent>
  )
  return (
    <Page className="c-background-light-gradient" mainContainerId={pageId} header={Header} sidebar={Sidebar} onPageResize={onPageResize} skipToContent={PageSkipToContent}>
      {children}
    </Page>
  )
}

export { AppLayout }
