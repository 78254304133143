import { ITenant, ICreateTenantPayload, IUpdateTenantPayload, IApiResponse } from '@lib/Tenant/ITenant'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import axios, { Axios, AxiosInstance } from 'axios'
import Cookies from 'universal-cookie'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'

export default class Tenant {
  static CookieName = 'BACKOFFICE_TENANT_ID'

  public constructor() {}

  public static async fetchTenantsPub(): Promise<ITenant[]> {
    try {
      const response = await axios.get(`${BackofficeAPIConfig.VersaURL}/?action=list-tenants`)
      return response.data['tenants']
    } catch (error: any) {
      console.error('Error fetching tenants:', error.message)
      return []
    }
  }

  public static async listTenants(api: AxiosInstance | undefined): Promise<ITenant[]> {
    if (!api) return []
    try {
      const response = await api.post(`${BackofficeAPIConfig.Domains.Tenant}`, { action: BackofficeApiActions.ListTenants, data: {} })
      return Array.isArray(response?.data?.result) ? response.data.result : []
    } catch (error: any) {
      console.error('Error fetching tenants:', error.message)
      return []
    }
  }

  public static async createTenant(payload: ICreateTenantPayload, api: AxiosInstance): Promise<IApiResponse> {
    try {
      const response = await api.post(`${BackofficeAPIConfig.Domains.Tenant}`, { action: BackofficeApiActions.CreateTenant, data: payload })
      return response.data.result
    } catch (error: any) {
      console.error('Error creating tenant:', error.message)
      return {
        success: false,
        error: 'Failed to create tenant',
        errorMessage: error.message,
      }
    }
  }

  public static async updateTenant(api: AxiosInstance, payload: IUpdateTenantPayload): Promise<IApiResponse> {
    try {
      const response = await api.post(`${BackofficeAPIConfig.Domains.Tenant}`, {
        action: BackofficeApiActions.UpdateTenant,
        data: payload,
      })
      return response.data.result
    } catch (error: any) {
      console.error('Error updating tenant:', error.message)
      return {
        success: false,
        error: 'Failed to update tenant',
        errorMessage: error.message,
      }
    }
  }

  public static async getTenant(tenantId: number, api: AxiosInstance): Promise<ITenant | null> {
    try {
      const response = await api.post(`${BackofficeAPIConfig.Domains.Tenant}`, { action: BackofficeApiActions.ReadTenant, data: { tenantId } })
      return response.data.result
    } catch (error: any) {
      console.error('Error fetching tenant:', error.message)
      return null
    }
  }

  public static async deleteTenant(api: AxiosInstance, tenantId: number): Promise<IApiResponse> {
    try {
      const response = await api.post(`${BackofficeAPIConfig.Domains.Tenant}`, {
        action: BackofficeApiActions.DeleteTenant,
        data: { tenantId },
      })
      return response.data.result
    } catch (error: any) {
      console.error('Error deleting tenant:', error.message)
      return {
        success: false,
        error: 'Failed to delete tenant',
        errorMessage: error.message,
      }
    }
  }

  public static setTenant(id) {
    const cookies = new Cookies(null, { path: '/' })
    cookies.set(this.CookieName, id)
  }

  public static getCurrentTenant() {
    const cookies = new Cookies(null, { path: '/' })
    const id = cookies.get(this.CookieName)
    const tenant = this.getTenants().find((p) => p.tenantId === id)

    return tenant
  }

  public static setTenants(tenants: ITenant[]) {
    const cookies = new Cookies(null, { path: '/' })

    cookies.set('BACKOFFICE_TENANTS', JSON.stringify(tenants))
  }

  public static getTenants() {
    const cookies = new Cookies(null, { path: '/' })
    const tenants = cookies.get('BACKOFFICE_TENANTS') as ITenant[]

    if (tenants) {
      return tenants
    } else {
      return []
    }
  }
}
