import { v4 as uuidv4 } from 'uuid'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import {
  ActionGroup,
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Grid,
  GridItem,
  NumberInput,
  Spinner,
  TextInput,
  TextInputGroup,
  TextInputGroupMain,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { TagInput } from '@app/components/textInput/TagInput'
import { PlusIcon, TrashIcon } from '@patternfly/react-icons'
import { ApiPermissionState, IApiKey } from './IApiKey'
import { ITenant } from '../../../lib/Tenant/ITenant'
import { IEndpoint } from './IEndpoint'
import { sortBy, uniq } from 'lodash'

const componentCreateReadUpdateApiKey: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Creating API key',
    read: 'Viewing API key',
    update: 'Updating API key',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<number | undefined>(props.data.id)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [apiKey, setApiKey] = React.useState<IApiKey | undefined>(undefined)

  const [formHandle, setFormHandle] = React.useState<string>('')
  const [formTenantId, setFormTenantId] = React.useState<number>(0)
  const [formPermissions, setFormPermissions] = React.useState<{ path: string; state: ApiPermissionState }[]>([])

  const [genKey, setGenKey] = React.useState<string>(uuidv4())

  const [helperTenants, setHelperTenants] = React.useState<ITenant[]>([])
  const [helperEndpoints, setHelperEndpoints] = React.useState<Record<string, IEndpoint>>({})
  const [helperPermissionPaths, setHelperPermissionPaths] = React.useState<string[]>([])
  const [helperPermissionStates, setHelperPermissionStates] = React.useState<ApiPermissionState[]>([])

  React.useEffect(() => {
    setLoading(true)
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchApiKey = async (id: number) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.ApiKeys, {
          action: BackofficeApiActions.ReadApiKey,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as IApiKey
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchTenants = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.ApiKeys, {
          action: BackofficeApiActions.ListTenants,
        })
        if (response?.data.result) {
          return response?.data.result as ITenant[]
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchEndpoints = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.ApiKeys, {
          action: BackofficeApiActions.ListEndpoints,
        })
        if (response?.data.result) {
          return response?.data.result as Record<string, IEndpoint>
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchPermissionStates = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.ApiKeys, {
          action: BackofficeApiActions.listPermissionStates,
        })
        if (response?.data.result) {
          return response?.data.result as ApiPermissionState[]
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id?: number) => {
      let tempPermissionStates = [] as ApiPermissionState[]
      let tempPermissionPaths = [] as string[]
      let tempFormPermissions = [] as { path: string; state: ApiPermissionState }[]

      const tenants = await fetchTenants()
      if (tenants) {
        setHelperTenants(tenants)
      }

      if (id) {
        const fetchedApiKey = await fetchApiKey(id)
        if (typeof fetchedApiKey !== 'undefined') {
          setApiKey(fetchedApiKey)
          setFormHandle(fetchedApiKey.handle)
          setFormTenantId(fetchedApiKey.tenantId)
          tempFormPermissions = Object.keys(fetchedApiKey.permissions.endpoints).map((key) => ({ path: key, state: fetchedApiKey.permissions.endpoints[key] }))
          tempPermissionPaths = tempFormPermissions.map((n) => n.path)
          tempPermissionStates = tempFormPermissions.map((n) => n.state)
        }
      }

      if (['create', 'update'].includes(action)) {
        if (tempFormPermissions.length === 0) {
          tempFormPermissions = [{ path: '', state: ApiPermissionState.FULL }]
        }

        const permissionStates = await fetchPermissionStates()
        if (permissionStates) {
          tempPermissionStates = permissionStates
        }

        const endpoints = await fetchEndpoints()
        if (endpoints) {
          setHelperEndpoints(endpoints)
          const keys = Object.keys(endpoints)
          const basePaths = keys.map((n) => endpoints[n].basePath)
          const fullPaths = keys.map((n) => endpoints[n].fullPath)
          const hardcoded = [
            'all',
            'customers',
            'customers/{id}',
            'customers/count',
            'data_definitions',
            'data_definitions/{id}',
            'data_definitions/count',
            'evaluations',
            'evaluations/{id}',
            'evaluations/count',
            'formulations',
            'formulations/{id}',
            'formulations/count',
            'ingredients',
            'ingredients/{id}',
            'ingredients/count',
            'models',
            'models/{id}',
            'models/count',
            'products',
            'products/{id}',
            'products/count',
          ]
          tempPermissionPaths = uniq([...basePaths, ...fullPaths, ...hardcoded]).sort()
        }
      }
      setHelperPermissionPaths(tempPermissionPaths)
      setHelperPermissionStates(tempPermissionStates)
      setFormPermissions(tempFormPermissions)
    }

    fetchAll(id)
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/api_keys`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (![formHandle, formTenantId].every((n) => n)) {
      setValidated('error')
      return
    }

    let defaultData = apiKey || { metadata: {} }

    let data: Record<string, any> = {
      ...defaultData,
      handle: formHandle,
      tenantId: formTenantId,
      keyValue: action === 'create' ? genKey : undefined,
      permissions: {
        signature: '',
        endpoints: formPermissions.reduce((obj, n) => {
          obj[n.path] = n.state
          return obj
        }, {} as Record<string, ApiPermissionState>),
      },
    }

    setSending(true)
    setSaved(false)

    api.current
      ?.post(BackofficeAPIConfig.Domains.ApiKeys, {
        action: action === 'create' ? BackofficeApiActions.CreateApiKey : BackofficeApiActions.UpdateApiKey,
        data,
      } as BackofficeApiEventSchema)
      .then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
        if (response?.data.result) {
          const { id } = response.data.result as IApiKey
          if (action === 'create') {
            setAction('update')
            setId(id)
            history.replace('/api_keys/update/' + id)
          }
          setSaved(true)
          if (props.data.toggleRefresh) {
            props.data.toggleRefresh()
          }
        } else {
          throw new Error('Failed to get response.')
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setSending(false)
      })
  }

  const updatePermissionPath = (index: number, path: string) => {
    setFormPermissions(
      formPermissions.map((n, i) => {
        if (i === index) {
          n.path = path
        }
        return n
      })
    )
  }

  const updatePermissionState = (index: number, state: ApiPermissionState) => {
    setFormPermissions(
      formPermissions.map((n, i) => {
        if (i === index) {
          n.state = state
        }
        return n
      })
    )
  }

  const clickAddPermission = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setFormPermissions([...formPermissions, { path: '', state: ApiPermissionState.READ }])
  }

  const clickRemovePermission = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault()

    setFormPermissions(formPermissions.filter((_, i) => i !== index))
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Formulation saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={12}>
              <FormGroup label="Handle" isRequired>
                <TextInput isRequired id="formHandle" type="text" value={formHandle} readOnly={isReadOnly} onChange={(val) => setFormHandle(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Tenant" isRequired>
                <FormSelect
                  isRequired
                  id={'tenant_id'}
                  value={formTenantId}
                  readOnly={isReadOnly}
                  onChange={(tid) => {
                    setFormTenantId(parseInt(tid, 10))
                  }}
                >
                  <FormSelectOption key={-1} value={''} label="Select tenant..." />
                  {helperTenants.map((tenant, index) => (
                    <FormSelectOption key={index} value={tenant.tenantId} label={tenant.handle} />
                  ))}
                </FormSelect>
              </FormGroup>
            </GridItem>
            {action === 'create' && (
              <GridItem span={12}>
                <FormGroup label="API Key (copy before submitting, only shown now)" isRequired>
                  <TextInput isRequired id="genKey" type="text" value={genKey} readOnly={true}></TextInput>
                </FormGroup>
              </GridItem>
            )}
            <GridItem span={12}>
              <FormGroup label="Permissions" isRequired>
                {formPermissions.map((item, index) => (
                  <Grid key={'permission' + index} hasGutter>
                    <GridItem span={4}>
                      <FormSelect
                        isRequired
                        id={'permission_path' + index}
                        value={item.path}
                        readOnly={isReadOnly}
                        onChange={(pp) => {
                          updatePermissionPath(index, pp)
                        }}
                      >
                        <FormSelectOption key={-1} value={''} label="Select path..." />
                        {helperPermissionPaths.map((permissionPath, index) => (
                          <FormSelectOption key={index} value={permissionPath} label={permissionPath} />
                        ))}
                      </FormSelect>
                    </GridItem>
                    <GridItem span={4}>
                      <FormSelect
                        isRequired
                        id={'permission_state' + index}
                        value={item.state}
                        readOnly={isReadOnly}
                        onChange={(ps) => {
                          updatePermissionState(index, ps as ApiPermissionState)
                        }}
                      >
                        {helperPermissionStates.map((permissionState, index) => (
                          <FormSelectOption key={index} value={permissionState} label={permissionState} />
                        ))}
                      </FormSelect>
                    </GridItem>
                    <GridItem span={4}>
                      {!isReadOnly && (
                        <Toolbar>
                          <ToolbarContent alignment={{ default: 'alignRight' }}>
                            <ToolbarGroup alignment={{ default: 'alignRight' }} variant="icon-button-group">
                              {index + 1 === formPermissions.length && (
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="view" onClick={(e) => clickAddPermission(e)}>
                                    <PlusIcon></PlusIcon>
                                  </Button>
                                </ToolbarItem>
                              )}
                              <ToolbarItem>
                                <Button variant="plain" aria-label="delete" onClick={(e) => clickRemovePermission(e, index)}>
                                  <TrashIcon></TrashIcon>
                                </Button>
                              </ToolbarItem>
                            </ToolbarGroup>
                          </ToolbarContent>
                        </Toolbar>
                      )}
                    </GridItem>
                  </Grid>
                ))}
              </FormGroup>
            </GridItem>
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const CreateReadUpdateApiKey = React.memo(componentCreateReadUpdateApiKey)

export { CreateReadUpdateApiKey }
