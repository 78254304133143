import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Form,
  FormGroup,
  TextInput,
  TextArea,
  ActionGroup,
  PageSection,
  Title,
  Alert,
  Spinner,
  FormHelperText,
  HelperText,
  HelperTextItem,
  Bullseye,
} from '@patternfly/react-core'
import { ITenant, ICreateTenantPayload, IUpdateTenantPayload } from '@lib/Tenant/ITenant'
import Tenant from '@lib/Tenant/Tenant'
import { useAxios } from '@app/utils/useAxios'
import axios from 'axios'

interface RouteParams {
  id?: string
}

interface FormErrors {
  handle?: string
  connectionString?: string
  backofficerealm?: string
  [key: string]: string | undefined
}

export const TenantForm: React.FC = () => {
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const isEditing = Boolean(id)
  const axiosInstance = useAxios()
  const api = axiosInstance.current

  const [formData, setFormData] = useState<ICreateTenantPayload>({
    handle: '',
    connectionString: '',
    metadata: {
      description: '',
      environment: '',
    },
    backofficerealm: '',
  })
  const [errors, setErrors] = useState<FormErrors>({})
  const [isLoading, setIsLoading] = useState(isEditing)
  const [error, setError] = useState<string | null>(null)
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    if (isEditing && id) {
      const fetchTenant = async () => {
        try {
          setError(null)
          setIsLoading(true)
          if (!api) {
            setError('Initializing connection...')
            return
          }
          const tenant = await Tenant.getTenant(parseInt(id), api)
          if (tenant) {
            setFormData({
              handle: tenant.handle,
              connectionString: tenant.connectionString,
              metadata: tenant.metadata,
              backofficerealm: tenant.backofficeRealm,
            })
          }
        } catch (error) {
          setError('Error loading tenant data. Please try again later.')
          console.error('Error fetching tenant:', error)
        } finally {
          setIsLoading(false)
        }
      }
      fetchTenant()
    }
  }, [id, api])

  const validateForm = () => {
    const newErrors: FormErrors = {}

    if (!formData.handle.trim()) {
      newErrors.handle = 'Handle is required'
    } else if (!/^[a-z0-9-]+$/.test(formData.handle)) {
      newErrors.handle = 'Handle can only contain lowercase letters, numbers, and hyphens'
    }

    if (!formData.connectionString.trim()) {
      newErrors.connectionString = 'Connection string is required'
    }

    if (!formData.backofficerealm.trim()) {
      newErrors.backofficerealm = 'Backoffice realm is required'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setTouched({
      handle: true,
      connectionString: true,
      backofficerealm: true,
    })

    if (!validateForm()) {
      return
    }

    try {
      setError(null)

      if (isEditing) {
        const updatePayload: IUpdateTenantPayload = {
          ...formData,
          tenantId: parseInt(id!),
        }
        if (!api) {
          setError('Please wait while the connection is being initialized...')
          return
        }
        const response = await Tenant.updateTenant(api, updatePayload)

        if (response.success) {
          history.push('/system_admin/tenants')
        } else {
          setError(`${response.error}: ${response.errorMessage}`)
        }
      } else {
        if (!api) {
          setError('Please wait while the connection is being initialized...')
          return
        }
        const response = await Tenant.createTenant(formData, api)

        if (response.success) {
          history.push('/system_admin/tenants')
        } else {
          setError(`${response.error}: ${response.errorMessage}`)
        }
      }
    } catch (error) {
      setError('Error saving tenant. Please try again later.')
      console.error('Error saving tenant:', error)
    }
  }

  const handleInputChange = (value: string, event: React.FormEvent<HTMLInputElement>) => {
    const { name } = event.currentTarget
    setTouched((prev) => ({
      ...prev,
      [name.split('.')[0]]: true,
    }))

    if (name.startsWith('metadata.')) {
      const metadataField = name.split('.')[1]
      setFormData((prev) => ({
        ...prev,
        metadata: {
          ...prev.metadata,
          [metadataField]: value,
        },
      }))
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }))
    }

    // Clear error when user starts typing
    if (errors[name.split('.')[0]]) {
      setErrors((prev) => ({
        ...prev,
        [name.split('.')[0]]: undefined,
      }))
    }
  }

  const getValidationState = (fieldName: string) => {
    if (!touched[fieldName]) return 'default'
    return errors[fieldName] ? 'error' : 'success'
  }

  if (isLoading) {
    return (
      <Bullseye>
        <Spinner size="xl" />
      </Bullseye>
    )
  }

  return (
    <>
      <PageSection variant="light">
        <Title headingLevel="h1" size="lg">
          {isEditing ? 'Edit tenant' : 'Create new tenant'}
        </Title>
      </PageSection>
      <PageSection>
        {error && (
          <Alert variant={error.includes('initializing') ? 'info' : 'danger'} title={error.includes('initializing') ? 'Please Wait' : 'Error'} isInline className="pf-v5-u-mb-md">
            {error}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <FormGroup label="Name" isRequired fieldId="handle" helperTextInvalid={errors.handle} validated={getValidationState('handle')}>
            <TextInput type="text" id="handle" name="handle" value={formData.handle} onChange={handleInputChange} isRequired validated={getValidationState('handle')} />
          </FormGroup>
          <FormGroup
            label="Database connection string"
            isRequired
            fieldId="connectionString"
            helperTextInvalid={errors.connectionString}
            validated={getValidationState('connectionString')}
          >
            <TextInput
              type="text"
              id="connectionString"
              name="connectionString"
              value={formData.connectionString}
              onChange={handleInputChange}
              isRequired
              validated={getValidationState('connectionString')}
            />
          </FormGroup>
          <FormGroup label="Description" fieldId="description">
            <TextInput type="text" id="description" name="metadata.description" value={formData.metadata?.description || ''} onChange={handleInputChange} />
          </FormGroup>
          <FormGroup label="Metadata" fieldId="metadata">
            <TextArea
              id="metadata"
              name="metadata"
              value={JSON.stringify(formData.metadata || {}, null, 2)}
              onChange={(value) => {
                try {
                  const parsedMetadata = JSON.parse(value)
                  setFormData((prev) => ({
                    ...prev,
                    metadata: parsedMetadata,
                  }))
                } catch (e) {
                  // If JSON is invalid, don't update the state
                  console.error('Invalid JSON:', e)
                }
              }}
              rows={12}
            />
          </FormGroup>
          <FormGroup label="User group" isRequired fieldId="backofficerealm" helperTextInvalid={errors.backofficerealm} validated={getValidationState('backofficerealm')}>
            <TextInput
              type="text"
              id="backofficerealm"
              name="backofficerealm"
              value={formData.backofficerealm}
              onChange={handleInputChange}
              isRequired
              validated={getValidationState('backofficerealm')}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" type="submit">
              {isEditing ? 'Update' : 'Create'} tenant
            </Button>
            <Button variant="link" onClick={() => history.push('/system_admin/tenants')}>
              Cancel
            </Button>
          </ActionGroup>
        </Form>
      </PageSection>
    </>
  )
}

export default TenantForm
