import React from 'react'
import ReactDOM from 'react-dom'
import App from '@app/index'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Moment from 'react-moment'
import 'moment-timezone'
import Keycloak from 'keycloak-js'
import TenantProvider from '@lib/Tenant/TenantProvider'
import Tenant from '@lib/Tenant/Tenant'

Moment.globalLocale = 'en'
Moment.globalTimezone = 'Europe/Copenhagen'
Moment.globalFormat = 'YYYY-MM-DD HH:mm:ss'

const keycloakRealm = Tenant.getCurrentTenant()?.backofficeRealm

let keycloakClientId = ''
if (process.env.NODE_ENV === 'production' && process.env.API === 'tenant') {
  keycloakClientId = 'backoffice_tenant_prod'
} else if (process.env.NODE_ENV === 'production' && process.env.API === 'services') {
  keycloakClientId = 'backoffice_prod'
} else {
  keycloakClientId = 'backoffice_local'
}

const keycloak = new Keycloak({
  realm: keycloakRealm ? keycloakRealm : '',
  //url: process.env.NODE_ENV === 'production' ? 'https://identity.noie.com/' : 'https://identity-staging.noie.com/',
  url: 'https://identity.noie.com/',
  clientId: 'backoffice_tenant_prod',
})

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens)
}
// Full option list and impl.: https://www.keycloak.org/docs/latest/securing_apps/index.html
const authOptions = {
  onLoad: 'login-required',
}

ReactDOM.render(
  <React.StrictMode>
    <TenantProvider>
      <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger} initOptions={authOptions}>
        <App />
      </ReactKeycloakProvider>
    </TenantProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
)
